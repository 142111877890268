<template>
    <div class="accrual-view">
        <div class="ctrl-view">
            <div class="label">报表月份：</div>
            <n-select
                class="input"
                style="width: 110px"
                size="small"
                :options="reportOptions"
                v-model:value="selectReport"
                @update:value="onSelectReportChange"></n-select>
            <div style="width: 0; margin-left: auto"><!-- 这是一个挡板 --></div>
            <template v-if="has3sOpPromission">
                <n-button
                    size="small"
                    style="margin-right: 4px"
                    :loading="withdrawMonthReportLoading"
                    @click="onWithdrawBtnClick"
                    >撤回当前月提交</n-button
                >
                <n-button size="small" @click="onApplyBtnClick"
                    >3S提交前预览</n-button
                >
            </template>
            <n-button
                style="margin-left: 4px"
                size="small"
                type="primary"
                @click="showDataItemEditorDrawer = true">
                <template #icon>
                    <n-icon>
                        <AddSharp></AddSharp>
                    </n-icon>
                </template>
                添加数据
            </n-button>
        </div>
        <div class="data-view">
            <n-data-table
                :columns="tableColumns"
                :data="tableData"
                :loading="reportLoading"
                :row-class-name="deletedRow"
                :scroll-x="2400">
            </n-data-table>
        </div>
        <n-drawer
            v-model:show="showDataItemEditorDrawer"
            :width="600"
            @after-leave="refreshEditor">
            <n-drawer-content
                :title="
                    (selectedDataIndex > -1 ? '编辑' : '添加') + '计提数据'
                ">
                <n-form
                    ref="addDataItemForm"
                    :model="editForm"
                    :rules="editFormRule"
                    label-placement="left">
                    <n-form-item label="业务月份" path="month">
                        <n-date-picker
                            v-model:formatted-value="editForm.month"
                            type="month"></n-date-picker>
                    </n-form-item>
                    <!-- <n-form-item label="计提月份" path="accountMonth">
                        <n-date-picker
                            v-model:formatted-value="editForm.accountMonth"
                            type="month"
                            disabled
                        ></n-date-picker>
                    </n-form-item> -->
                    <n-form-item label="我方主体名称" path="companyCode">
                        <n-select
                            v-model:value="editForm.companyCode"
                            style="width: 300px; margin-right: 20px"
                            :options="companyOptions"
                            :consistent-menu-width="false"
                            filterable></n-select>
                        编码：{{ editForm.companyCode || '-' }}
                    </n-form-item>
                    <n-form-item label="BU">
                        <n-select
                            v-model:value="editForm.projectBu"
                            :options="projectBuList"
                            @update:value="onBUChange"></n-select>
                    </n-form-item>
                    <n-form-item label="项目">
                        <n-select
                            v-model:value="editForm.projectName"
                            :options="projectList"
                            @update:value="onProjectChange"></n-select>
                    </n-form-item>
                    <n-form-item label="一级项目">
                        <n-select
                            v-model:value="editForm.projectSub1"
                            :options="projectSub1List"
                            @update:value="onProjectSub1Change"></n-select>
                    </n-form-item>
                    <n-form-item label="二级项目">
                        <n-select
                            v-model:value="editForm.projectSub2"
                            :options="projectSub2List"></n-select>
                    </n-form-item>
                    <n-form-item label="币种">
                        <n-select
                            style="width: 100px"
                            v-model:value="editForm.currency"
                            :options="[
                                { label: 'CNY', value: 'CNY' },
                                { label: 'USD', value: 'USD' },
                            ]"></n-select>
                    </n-form-item>
                    <n-form-item label="含税金额" path="usdAmountTaxed">
                        <n-input-number
                            v-model:value="editForm.usdAmountTaxed"
                            :precision="2"
                            clearable
                            @update:value="onUSDAmountTaxedChange">
                            <template #prefix>
                                {{ editForm.currency === 'USD' ? '$' : '￥' }}
                            </template>
                        </n-input-number>
                    </n-form-item>
                    <n-form-item label="税率">
                        <n-select
                            style="width: 100px"
                            v-model:value="editForm.taxRate"
                            :options="[
                                { label: '0%', value: 0 },
                                { label: '1%', value: 0.01 },
                                { label: '3%', value: 0.03 },
                                { label: '6%', value: 0.06 },
                            ]"
                            @update:value="onTaxRateChange"></n-select>
                    </n-form-item>
                    <n-form-item label="不含税金额" path="usdAmount">
                        <n-input-number
                            v-model:value="editForm.usdAmount"
                            :precision="2"
                            clearable
                            :readonly="autoUsdAmount"
                            ><template #prefix>
                                {{ editForm.currency === 'USD' ? '$' : '￥' }}
                            </template>
                        </n-input-number>
                        <!-- <n-checkbox
                            v-model:checked="autoUsdAmount"
                            size="small"
                            style="margin-left: 8px"
                            >根据含税金额自动计算</n-checkbox
                        > -->
                    </n-form-item>
                    <n-form-item label="供应商名称" path="supplierCode">
                        <n-select
                            v-model:value="editForm.supplierCode"
                            style="width: 300px; margin-right: 20px"
                            :options="supplierOptions"
                            :consistent-menu-width="false"
                            filterable
                            clearable></n-select>
                        编码：{{ editForm.supplierCode || '-' }}
                    </n-form-item>
                </n-form>
                <template #footer>
                    <n-button
                        type="primary"
                        :loading="isSubmiting"
                        @click="onEditorSubmit">
                        确认
                    </n-button>
                </template>
            </n-drawer-content>
        </n-drawer>
        <n-drawer
            v-model:show="showApplyReviewDrawer"
            placement="bottom"
            default-height="500">
            <n-drawer-content title="提交报表至3S的数据预览">
                <div class="warning-list">
                    <n-alert
                        v-for="(item, index) in warningList"
                        :key="'alert_' + index"
                        class="warning-box"
                        :title="item.error_msg"
                        type="warning"
                        closable
                        @close="warningCount = warningCount - 1">
                        主体：{{ item.companyName }} <br />
                        供应商：{{ item.supplierName }}
                    </n-alert>
                </div>
                <div class="review-tables-view">
                    <n-data-table
                        class="table"
                        :columns="companySummaryTableColumns"
                        :data="companySummaryTableData"
                        :loading="reviewDataIsLoading"
                        size="small"></n-data-table>
                    <n-data-table
                        class="table"
                        :columns="supplierSummaryTableColumns"
                        :data="supplierSummaryTableData"
                        :loading="reviewDataIsLoading"
                        size="small"></n-data-table>
                </div>
                <template #footer>
                    <n-tag
                        v-if="warningCount > 0"
                        type="warning"
                        style="margin-right: 10px">
                        需要确认所有异常提示之后才能提交（关闭上方异常信息提示卡）
                    </n-tag>
                    <n-button
                        :loading="applyIsSubmitting"
                        :disabled="warningCount > 0"
                        type="primary"
                        @click="onApplySubmitBtnClick"
                        >确认无误，提交</n-button
                    >
                </template>
            </n-drawer-content>
        </n-drawer>
        <n-modal
            v-model:show="showAttachmentModal"
            @after-leave="onAttachmentModalClose">
            <n-card title="附件管理" size="small" style="width: 400px">
                <n-upload
                    :action="
                        AccrualViewAPI.getAttachmentUploadUrl(
                            localAttachAccrualId
                        )
                    "
                    :with-credentials="true"
                    :file-list="attachments"
                    @change="onAttachmentAdd"
                    @remove="onAttachmentRemove"
                    @finish="onAttachmentUploadFinish">
                    <n-button
                        type="primary"
                        size="small"
                        :loading="isAttachmentsLoading">
                        <template #icon>
                            <n-icon>
                                <AttachFileSharp />
                            </n-icon>
                        </template>
                        上传附件
                    </n-button>
                </n-upload>
            </n-card>
        </n-modal>
        <n-modal v-model:show="showSingleAccrualSubmitModal">
            <n-card
                :title="`[${selectedAccrualData.id}]单条计提提交`"
                size="small"
                style="width: 300px">
                <div class="submit-3s-status">
                    <div class="item">
                        <span class="label">提交状态</span>
                        {{ selectedAccrualData.sentTo3s ? '已提交' : '未提交' }}
                    </div>
                    <div class="item">
                        <span class="label">上次提交操作人</span>
                        {{ selectedAccrualData.sendTo3sOperator }}
                    </div>
                    <div class="item">
                        <span class="label">上次提交操作</span>
                        {{ selectedAccrualData.sendTo3sLastOperation }}
                    </div>
                    <div class="item">
                        <span class="label">上次提交是否成功</span>
                        {{
                            selectedAccrualData.sendTo3sLastOperationSucceeded
                                ? '成功'
                                : selectedAccrualData.sendTo3sLastOperation
                                ? '失败'
                                : ''
                        }}
                    </div>
                    <div class="item">
                        <span class="label">3S返回信息</span>
                        {{ selectedAccrualData.sendTo3sMsg }}
                    </div>
                </div>
                <template #action>
                    <div
                        v-if="has3sOpPromission"
                        class="submit-3s-status-action">
                        <n-button
                            size="small"
                            style="margin-right: 4px"
                            :loading="singleAccrualSubmiting"
                            @click="onWithdrawAccrualSubmit"
                            >撤回</n-button
                        >
                        <n-button
                            type="primary"
                            size="small"
                            :loading="singleAccrualSubmiting"
                            @click="onSingleAccrualSubmit"
                            >提交</n-button
                        >
                    </div>
                </template>
            </n-card>
        </n-modal>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.accrual-view {
    .common-content;

    .ctrl-view {
        position: relative;
        padding: 4px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
        .standard-border;

        .label {
            font-weight: bold;
            white-space: nowrap;
        }

        .input {
            margin-right: 10px;
        }
    }

    .data-view {
        background-color: #fff;
        margin-top: 10px;
    }
}
.review-tables-view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .table {
        width: 49.5%;
    }
}

.warning-list {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;

    .warning-box {
        margin-right: 10px;
    }
}

.submit-3s-status {
    .item {
        .label {
            font-weight: bold;
            margin-right: 4px;
            &::after {
                content: ':';
            }
        }
    }
}

.submit-3s-status-action {
    display: flex;
    flex-direction: row;
    justify-content: right;
}
</style>

<script setup>
import { ref, h, onMounted, computed, nextTick } from 'vue';
import {
    NButton,
    NSelect,
    NIcon,
    NDataTable,
    NForm,
    NFormItem,
    NDrawer,
    NDrawerContent,
    NDatePicker,
    NInputNumber,
    NAlert,
    NModal,
    NCard,
    NUpload,
    NTag,
    useMessage,
    useDialog,
} from 'naive-ui';
import { AddSharp, AttachFileSharp } from '@vicons/material';

import { useUserStore } from '@/stores/global';

import CtrlCell from '@/components/CtrlCell';

import { AccrualViewAPI } from '@/common/API';

const message = useMessage();

const dialog = useDialog();

const userStore = useUserStore();

// 主列表数据

let reportOptions = ref([]);

let selectReport = ref('');

function loadEnableDates() {
    message.loading('检查可用报表...');
    // 获取可用报表月份列表
    AccrualViewAPI.getEnableDates().then(res => {
        message.destroyAll();
        if (res.error_no !== 0) {
            return;
        }
        reportOptions.value = res.data.map(item => {
            return { label: item, value: item };
        });
        if (res.data.length < 1) {
            message.info('未找到可用报表...');
            return;
        }
        if (selectReport.value === '') {
            selectReport.value = reportOptions.value[res.data.length - 1].value;
        }
        loadReport();
    });
}

loadEnableDates();

function onSelectReportChange(value) {
    loadReport(value);
}

let tableColumns = ref([
    {
        title: 'ID',
        key: 'id',
        width: 60,
    },
    {
        title: '数据说明',
        key: 'desc',
        resizable: true,
        minWidth: 200,
        ellipsis: true,
    },
    { title: '业务月份', key: 'month', width: 80 },
    {
        title: '提交状态',
        width: 80,
        align: 'center',
        render(row) {
            let title = row.sentTo3s ? '已提交' : '未提交';
            return h(
                NButton,
                {
                    type:
                        row.sendTo3sLastOperationSucceeded ||
                        row.sendTo3sLastOperation === null
                            ? 'primary'
                            : 'error',
                    quaternary: true,
                    size: 'tiny',
                    onClick: () => onSingleAccrualSelected(row),
                },
                {
                    default: () => title,
                }
            );
        },
    },
    {
        title: '我方主体名称',
        key: 'companyName',
        resizable: true,
        minWidth: 200,
        ellipsis: true,
    },
    { title: '我方主体编码', key: 'companyCode', width: 110 },
    { title: 'BU', key: 'projectBu', resizable: true, minWidth: 100 },
    {
        title: '项目',
        key: 'projectName',
        resizable: true,
        minWidth: 100,
        ellipsis: true,
    },
    {
        title: '一级项目',
        key: 'projectSub1',
        resizable: true,
        minWidth: 100,
        ellipsis: true,
    },
    {
        title: '二级项目',
        key: 'projectSub2',
        resizable: true,
        minWidth: 100,
        ellipsis: true,
    },
    { title: '币种', key: 'currency', width: 60 },
    { title: '含税金额', key: 'usdAmountTaxed', width: 100 },
    {
        title: '税率',
        key: 'taxRate',
        width: 60,
        render(row) {
            return row.taxRate * 100 + '%';
        },
    },
    { title: '不含税金额', key: 'usdAmount', width: 100 },
    {
        title: '供应商名称',
        key: 'supplierName',
        resizable: true,
        minWidth: 200,
        ellipsis: true,
    },
    { title: '供应商编码', key: 'supplierCode', width: 100 },
    {
        title: '附件管理',
        width: 80,
        align: 'center',
        render(row) {
            return h(
                NButton,
                {
                    size: 'tiny',
                    onClick: () => onAttachmentEdit(row),
                },
                {
                    default: () =>
                        h(NIcon, null, {
                            default: () => h(AttachFileSharp),
                        }),
                }
            );
        },
    },
    {
        title: '创建时间',
        key: 'create_time',
        width: 200,
    },
    {
        title: '更新时间',
        key: 'update_time',
        width: 200,
    },
    {
        title: '操作人',
        key: 'operator',
        width: 140,
        align: 'center',
    },
    {
        title: '操作',
        width: 80,
        align: 'center',
        fixed: 'right',
        render(row, index) {
            if (row.deleteFlag) {
                return '';
            }
            return h(CtrlCell, {
                row,
                deleteConfirm: '确定删除该条数据？',
                onEdit: () => onDataItemEdit(row, index),
                onDelete: () => onDataItemDelete(row),
            });
        },
    },
]);

let tableData = ref([]);

function deletedRow(row) {
    return row.deleteFlag ? 'deleted-row' : '';
}

let reportLoading = ref(false);

function loadReport(dateMonth) {
    let [year, month] = (dateMonth || selectReport.value).split('-');
    reportLoading.value = true;
    AccrualViewAPI.getReport(year, month)
        .then(res => {
            reportLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            let list = res.data.map(item => {
                return {
                    ...item,
                    desc: `计提广告服务器成本-${item.supplierName}-${item.month}`,
                };
            });
            tableData.value = list;
        })
        .catch(err => {
            reportLoading.value = false;
            message.error('未知错误导致加载失败');
        });
}

// 添加数据和编辑数据

let showDataItemEditorDrawer = ref(false);

let selectedDataIndex = ref(-1);

const addDataItemForm = ref();

function onDataItemEdit(row, index) {
    selectedDataIndex.value = index;
    editForm.value = { ...row };
    showDataItemEditorDrawer.value = true;
}

function onDataItemDelete(row) {
    message.loading('操作中...');
    AccrualViewAPI.deleteReport(row.id).then(res => {
        message.destroyAll();
        if (res.error_no !== 0) {
            return;
        }
        loadEnableDates();
        AccrualViewAPI.deleteAttachment(row.id);
    });
}

let companyList = {};
const companyOptions = computed(() => {
    let list = [];
    for (let key in companyList) {
        list.push({ label: key, value: companyList[key] });
    }
    return list;
});

let supplierList = ref({});
const supplierOptions = computed(() => {
    let list = [];
    for (let key in supplierList.value) {
        list.push({
            label: key,
            value: supplierList.value[key],
        });
    }
    return list;
});

let projectTree = {};

function getSelectOptionsFromProjectTree(parentArr) {
    let layerObj = projectTree;
    parentArr.forEach(key => {
        layerObj = layerObj[key];
    });
    return Object.keys(layerObj).map(item => ({ label: item, value: item }));
}

const projectBuList = computed(() => {
    return getSelectOptionsFromProjectTree([]);
});

const projectList = computed(() => {
    if (editForm.value.projectBu === '') {
        return [];
    }
    return getSelectOptionsFromProjectTree([editForm.value.projectBu]);
});

const projectSub1List = computed(() => {
    if (editForm.value.projectName === '') {
        return [];
    }
    return getSelectOptionsFromProjectTree([
        editForm.value.projectBu,
        editForm.value.projectName,
    ]);
});

const projectSub2List = computed(() => {
    if (
        editForm.value.projectName === '' ||
        editForm.value.projectSub1 === ''
    ) {
        return [];
    }
    return getSelectOptionsFromProjectTree([
        editForm.value.projectBu,
        editForm.value.projectName,
        editForm.value.projectSub1,
    ]);
});

function initEditorData() {
    AccrualViewAPI.getMVCompanyList().then(res => {
        if (res.error_no !== 0) {
            return;
        }
        companyList = res.data;
    });
    AccrualViewAPI.getProjectTree().then(res => {
        if (res.error_no !== 0) {
            return;
        }
        projectTree = res.data;
    });
    AccrualViewAPI.getSupplierCompanyList('').then(res => {
        if (res.error_no !== 0) {
            return;
        }
        supplierList.value = res.data;
    });
}

let isSubmiting = ref(false);

function originForm() {
    return {
        month: null,
        // accountMonth: null,
        companyName: '',
        companyCode: '',
        projectBu: '',
        projectName: '',
        projectSub1: '',
        projectSub2: '',
        currency: 'CNY',
        usdAmountTaxed: null,
        taxRate: 0.03,
        usdAmount: null,
        supplierName: '',
        supplierCode: '',
    };
}

let editFormRule = ref({
    month: {
        required: true,
        message: '业务月份不能为空',
        trigger: ['blur', 'submit'],
    },
    // accountMonth: {
    //     required: true,
    //     message: '计提月份不能为空',
    //     trigger: ['blur', 'submit'],
    // },
    companyCode: {
        required: true,
        message: '我方主体信息不能为空',
        trigger: ['blur', 'submit'],
    },
    usdAmountTaxed: {
        required: true,
        type: 'number',
        message: '含税金额不能为空',
        trigger: ['blur', 'submit'],
    },
    usdAmount: {
        required: true,
        type: 'number',
        message: '不含税金额不能为空',
        trigger: ['blur', 'submit'],
    },
    supplierCode: {
        required: true,
        message: '供应商信息不能为空',
        trigger: ['blur', 'submit'],
    },
});

let editForm = ref(originForm());

let autoUsdAmount = ref(true);

function onBUChange() {
    editForm.value.projectName = '';
    editForm.value.projectSub1 = '';
    editForm.value.projectSub2 = '';
}

function onProjectChange() {
    editForm.value.projectSub1 = '';
    editForm.value.projectSub2 = '';
}

function onProjectSub1Change() {
    editForm.value.projectSub2 = '';
}

function onUSDAmountTaxedChange(value) {
    if (!autoUsdAmount.value) {
        return;
    }
    editForm.value.usdAmount =
        (value / (1 + editForm.value.taxRate)).toFixed(2) * 1;
}

function onTaxRateChange() {
    if (!autoUsdAmount.value) {
        return;
    }
    editForm.value.usdAmount =
        (editForm.value.usdAmountTaxed / (1 + editForm.value.taxRate)).toFixed(
            2
        ) * 1;
}

function refreshEditor() {
    selectedDataIndex.value = -1;
    editForm.value = originForm();
}

function onEditorSubmit() {
    addDataItemForm.value
        .validate(errors => {
            if (errors) {
                return;
            }
            let companyName = Object.keys(companyList).find(
                key => companyList[key] === editForm.value.companyCode
            );

            let supplierName = Object.keys(supplierList.value).find(
                key => supplierList.value[key] === editForm.value.supplierCode
            );

            let dataItem = {
                ...editForm.value,
                companyName,
                supplierName,
            };
            let [year, month] = dataItem.month.split('-');
            isSubmiting.value = true;
            let func = dataItem.id
                ? AccrualViewAPI.updateReport(dataItem.id, dataItem)
                : AccrualViewAPI.createReport(year, month, dataItem);

            func.then(res => {
                isSubmiting.value = false;
                if (res.error_no !== 0) {
                    message.error(res.error_msg || '未知错误导致加载失败');
                    return;
                }
                showDataItemEditorDrawer.value = false;
                loadEnableDates();
            }).catch(err => {
                isSubmiting.value = false;
                message.error('未知错误导致加载失败');
            });
        })
        .catch(err => {});
}

// 3s提交前预览

let showApplyReviewDrawer = ref(false);

let reviewDataIsLoading = ref(false);

let applyIsSubmitting = ref(false);

let companySummaryTableColumns = ref([
    {
        title: '主体名称',
        key: 'companyName',
        resizable: true,
        ellipsis: true,
        minWidth: 200,
    },
    {
        title: '币种',
        key: 'currency',
    },
    {
        title: '费用(含税)',
        key: 'usdAmountTaxed',
    },
    {
        title: '费用(不含税)',
        key: 'usdAmount',
    },
]);

let companySummaryTableData = ref([]);

let supplierSummaryTableColumns = ref([
    {
        title: '供应商名称',
        key: 'supplierName',
        resizable: true,
        ellipsis: true,
        minWidth: 200,
    },
    {
        title: '币种',
        key: 'currency',
    },
    {
        title: '费用(含税)',
        key: 'usdAmountTaxed',
    },
    {
        title: '费用(不含税)',
        key: 'usdAmount',
    },
]);

let warningList = ref([]);

let supplierSummaryTableData = ref([]);

let warningCount = ref(0);

function onApplyBtnClick() {
    let [year, month] = selectReport.value.split('-');
    showApplyReviewDrawer.value = true;
    reviewDataIsLoading.value = true;
    AccrualViewAPI.reviewAndCheck(year, month)
        .then(res => {
            reviewDataIsLoading.value = false;
            if (res.error_no !== 0) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            companySummaryTableData.value = res.data.company_review_list;
            supplierSummaryTableData.value = res.data.supplier_review_list;
            warningList.value = res.data.warning_list;
            warningCount.value = warningList.value.length;
        })
        .catch(err => {
            console.log(err);
            reviewDataIsLoading.value = false;
            message.error('未知异常导致加载失败');
        });
}

function onApplySubmitBtnClick() {
    applyIsSubmitting.value = true;
    let [year, month] = selectReport.value.split('-');
    AccrualViewAPI.submitReport(year, month)
        .then(res => {
            applyIsSubmitting.value = false;
            if (res.error_no !== 0 && res.error_no !== 10500) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            if (res.error_no === 10500) {
                messageError(res);
            } else {
                message.success('提交成功!');
            }
            showApplyReviewDrawer.value = false;
            loadReport();
        })
        .catch(err => {
            console.log(err);
            applyIsSubmitting.value = false;
            message.error('未知异常导致加载失败');
        });
}

function onWithdrawBtnClick() {
    dialog.warning({
        title: '警告',
        content: '确定撤回当前月的所有计提提交？',
        positiveText: '确定',
        negativeText: '取消',
        onPositiveClick: () => {
            withdrawMonthReport();
        },
    });
}

let withdrawMonthReportLoading = ref(false);

function withdrawMonthReport() {
    withdrawMonthReportLoading.value = true;
    let [year, month] = selectReport.value.split('-');
    AccrualViewAPI.withdrawReport(year, month)
        .then(res => {
            withdrawMonthReportLoading.value = false;
            if (res.error_no !== 0 && res.error_no !== 10500) {
                message.error(res.error_msg || '未知异常导致加载失败');
                return;
            }
            if (res.error_no === 10500) {
                messageError(res);
            } else {
                message.success('提交成功!');
            }
            loadReport();
        })
        .catch(err => {
            console.log(err);
            withdrawMonthReportLoading.value = false;
            message.error('未知异常导致加载失败');
        });
}

// 附件管理

let showAttachmentModal = ref(false);

let localAttachAccrualId = ref(0);

let isAttachmentsLoading = ref(false);

let attachments = ref([]);

function onAttachmentEdit(info) {
    isAttachmentsLoading.value = true;
    localAttachAccrualId.value = info.id;
    AccrualViewAPI.getAttachmentFiles(info.id).then(res => {
        isAttachmentsLoading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        let fileList = res.data.file_list;
        attachments.value = fileList.map(item => {
            return {
                id: item,
                name: item,
                status: 'finished',
                url: AccrualViewAPI.getAttachmentUrl(info.id, item),
            };
        });
    });
    showAttachmentModal.value = true;
}

function onAttachmentAdd(data) {
    attachments.value = data.fileList;
}

function onAttachmentRemove(data) {
    AccrualViewAPI.deleteAttachment(
        localAttachAccrualId.value,
        data.file.name
    ).then(res => {
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        message.success('删除完成');
    });
}

function onAttachmentUploadFinish(data) {
    let res = JSON.parse(data.event.target.response);
    if (res.error_no === 0) {
        return;
    }
    message.error(res.error_msg || '未知异常导致加载失败');
    let newlist = [...attachments.value];
    nextTick(() => {
        attachments.value = newlist.filter(item => {
            return item.id !== data.file.id;
        });
    });
}

function onAttachmentModalClose() {
    attachments.value = [];
}

// 单条计提项提交
let showSingleAccrualSubmitModal = ref(false);

let selectedAccrualData = ref(null);

function onSingleAccrualSelected(row) {
    selectedAccrualData.value = row;
    showSingleAccrualSubmitModal.value = true;
}

let singleAccrualSubmiting = ref(false);

function onSingleAccrualSubmit() {
    let [year, month] = selectReport.value.split('-');
    singleAccrualSubmiting.value = true;
    AccrualViewAPI.submitSingleAccrual(
        year,
        month,
        selectedAccrualData.value.id
    ).then(res => {
        singleAccrualSubmiting.value = false;
        if (res.error_no !== 0 && res.error_no !== 10500) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        messageError(res);
        showSingleAccrualSubmitModal.value = false;
        loadReport();
    });
}

function onWithdrawAccrualSubmit() {
    let [year, month] = selectReport.value.split('-');
    singleAccrualSubmiting.value = true;
    AccrualViewAPI.withdrawSingleAccrual(
        year,
        month,
        selectedAccrualData.value.id
    ).then(res => {
        singleAccrualSubmiting.value = false;
        if (res.error_no !== 0 && res.error_no !== 10500) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        messageError(res);
        showSingleAccrualSubmitModal.value = false;
        loadReport();
    });
}

// batch error message
function messageError(res) {
    if (res.error_no === 0) {
        return;
    }
    let errors = res.data;
    errors.forEach(error => {
        message.error(`id:${error.id} 失败：${error.res.ret_msg.msg}`, {
            closable: true,
            duration: 0,
        });
    });
}

const has3sOpPromission = computed(() =>
    userStore.currentUser.roles.includes('financial_staff')
);

onMounted(() => {
    initEditorData();
});
</script>
